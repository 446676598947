<template>
  <div id="home">

    <!--body content wrap start-->
    <div class="main">

      <!--hero section start-->
      <section class="hero-section hero-section-2 ptb-100">
        <div class="circles">
          <div class="point animated-point-1"></div>
          <div class="point animated-point-2"></div>
          <div class="point animated-point-3"></div>
          <div class="point animated-point-4"></div>
          <div class="point animated-point-5"></div>
          <div class="point animated-point-6"></div>
          <div class="point animated-point-7"></div>
          <div class="point animated-point-8"></div>
          <div class="point animated-point-9"></div>
        </div>
        <div class="container">

          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <!--<li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>-->
<!--              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>-->
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row align-items-center justify-content-between">
                  <div class="col-md-6 col-lg-6">
                    <!-- CUSTOM HEADER COMPONENT -->
                    <div v-if="false" class="hero-content-left ptb-100 text-white">
                      <h1 class="text-white font-weight-bold">Prepara il tuo equipaggiamento</h1>
                      <p>Scopri il device as a service di Noltech</p>

                      <div class="configuration-container">
                        <div class="device-selection">
                          <multiselect
                              class="type"
                              :label="''"
                              v-model="selectedDevice"
                              :options="deviceOptions"
                              :label-name="'label'"
                              :value-name="'label'"
                              :open-direction="'bottom'"
                              :placeholder="'Scegli il dispositivo'"
                              :placeholder-icon="'/img/configuration/pcGray.svg'"
                              :label-icon="'/img/configuration/pcGray.svg'"
                              :showNoResult="false"
                          ></multiselect>
                          <my-input
                              :type-input="'number'"
                              class="quantity"
                              :name="'quantity'"
                              :icon="'/img/configuration/box.svg'"
                              v-model="deviceQuantity"
                              :label="''"
                              placeholder="Quantità"
                          ></my-input>
                          <!--                        <input class="quantity" type="number" placeholder="Quantità"/>-->
                        </div>

                        <button class="configuration-btn" @click="selectDevice(selectedDevice)">Mettici alla prova</button>
                      </div>


                    </div>
                    <div class="hero-content-left ptb-100 text-white">
                      <h1 class="text-white font-weight-bold label-coming-soon" style="font-size: 65px">Coming Soon</h1>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6">
                    <div class="hero-animation-img">
                      <img class="img-fluid d-none d-lg-block animation-one" src="/img/devicesMain.svg"
                           alt="animation image">
                      <img class="img-fluid d-lg-none animation-one" src="/img/devicesAll.svg"
                           alt="animation image">
                      <img class="img-fluid d-none d-lg-block animation-two"
                           src="/img/devices2.svg" alt="animation image" width="300">
                      <img class="img-fluid d-none d-lg-block animation-three"
                           src="/img/devicesIphone.svg" alt="animation image" width="100">
                      <!--  <img class="img-fluid d-none d-lg-block animation-three"
                             src="/img/devices2.svg" alt="animation image" width="120">
                        <img class="img-fluid d-none d-lg-block animation-four" src="/img/hero-animation-03.svg"
                             alt="animation image" width="230">-->
                    </div>
                  </div>
                </div>
              </div>
<!--              <div class="carousel-item">-->
<!--                <div class="row align-items-center justify-content-between">-->
<!--                  <div class="col-md-6 col-lg-6">-->
<!--                    <div class="hero-content-left ptb-100 text-white">-->
<!--                      <h1 class="text-white font-weight-bold">DIFENDI I DATI DELLA TUA AZIENDA-->
<!--                      </h1>-->
<!--                      <p class="lead">Noltech è il primo noleggio business con Cybersecurity integrata. </p>-->

<!--                      <a href="#iservizi" class="btn app-store-btn page-scroll">Scopri il servizio base</a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-6 col-lg-5">-->
<!--                    <div class="hero-animation-img">-->
<!--                      <img class="img-fluid d-none d-lg-block animation-one" src="/img/sicurezzaMain.svg"-->
<!--                           alt="animation image">-->
<!--                      <img class="img-fluid d-none d-lg-block animation-four"-->
<!--                           src="/img/sicurezzaAdd.svg" alt="animation image" width="160">-->
<!--                      <img class="img-fluid d-lg-none animation-one" src="/img/sicurezza.svg"-->
<!--                           alt="animation image">-->
<!--                      &lt;!&ndash;  <img class="img-fluid d-none d-lg-block animation-three"-->
<!--                            src="/img/hero-animation-02.svg" alt="animation image" width="120">-->
<!--                       <img class="img-fluid d-none d-lg-block animation-four" src="/img/hero-animation-03.svg"-->
<!--                            alt="animation image" width="230">&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

            </div>

          </div>


        </div>
      </section>
      <section v-if="false" id="bussola" class="promo-section ptb-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
              <div class="section-heading text-center mb-5">
                <h2>Orientati tra i servizi di gestione dei device</h2>
               <!-- <p class="lead">
                  Siamo la Business unit di Elmec Informatica che ha creato l’offerta per il noleggio device migliore di
                  sempre.
                </p>-->
              </div>
            </div>
          </div>
          <div class="row equal">
            <div class="col-md-4 col-lg-5">
              <img alt="Noltech, la tua bussola nel mondo del noleggio dei device" src="img/bussola.svg">
            </div>
            <div class="col-md-8 col-lg-7"><p class="lead">
              Budget limitato, dispositivi da aggiornare frequentemente, collaboratori attivi da remoto, nuove esigenze a cui rispondere rapidamente e difficoltà nella gestione degli asset: sappiamo quanto il percorso dell’IT Manager sia diventato intricato. Ma niente panico, siamo qui per guidarti! Con oltre 50 anni di esperienza nei servizi di gestione dei device, Noltech è la bussola che può aiutarti a orientarti nel mondo del DaaS rendendo il tuo viaggio semplice e sicuro.
            </p></div>
          </div>
        </div>
      </section>


      <section v-if="false" id="slider-steps" class="promo-section ptb-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
              <div style="margin-bottom: 4rem!important;" class="section-heading text-center mb-5">
                <h2>Il tuo viaggio insieme a Noltech</h2>
<!--                <p class="lead">-->
<!--                </p>-->
              </div>

              <slider :with-slots="true"/>
            </div>
          </div>
        </div>
      </section>


      <section v-if="false" id="videoclip" class="feature-section ptb-100 ">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">

                <div class="video-container">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/n-fbhN4zbEg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>



               </div>

          </div>
        </div>
      </section>




      <!--features section start-->
      <section v-if="false" id="iservizi" class="feature-section pt-100 gray-light-bg">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
              <div class="section-heading text-center mb-5">
                <h2>Scopri i nostri servizi</h2>
                <p class="lead">
                  La nostra offerta Device as a Service è totalmente personalizzabile in base alle esigenze della tua azienda. Scopri il nostro pacchetto base e tutti gli add-on che puoi richiedere
                  per gestire i tuoi dispositivi.
                </p>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-between">
            <div class="col-md-4">
              <div class="download-img">
                <img alt="Configura i servizi di gestione dei dispositivi da includere nel noleggio" src="img/zaino.svg">
              </div>
            </div>
            <div class="col-md-7">
              <div class="feature-contents section-heading">
               <!-- <h2>Scopri quanto è semplice.</h2>
                <p>Scopri la nostra offerta base e componila con i servizi di cui hai bisogno per i tuoi utenti. Scopri
                  di più.</p>-->

                <div class="feature-content-wrap">
                  <ul class="nav nav-tabs feature-tab" data-tabs="tabs">
                    <li class="nav-item">
                      <a class="nav-link active h6" href="#tab6-1" data-toggle="tab">
                        <span style="height: 40px"><img src="/img/noltechsmall.svg" alt="Add on" width="40"
                                                        class="img-fluid"
                                                        style="display: block; margin: auto;padding-bottom: 8px"></span>
                        NOLTECH START
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link h6" href="#tab6-2" data-toggle="tab">
                        <span style="height: 40px"><img src="/img/service.svg" alt="Add on" width="40" class="img-fluid"
                                                        style="display: block; margin: auto;padding-bottom: 8px"></span>
                        ADD-ON DI SERVIZIO
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link h6" href="#tab6-3" data-toggle="tab">
                        <span style="height: 40px"><img src="/img/user.svg" alt="Add on" width="40" class="img-fluid"
                                                        style="display: block; margin: auto;padding-bottom: 8px"></span>
                        USER ADD-ON
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link h6" href="#tab6-4" data-toggle="tab">
                        <span style="height: 40px"><img src="/img/myElmec.svg" alt="Add on" width="50" class="img-fluid"
                                                        style="display: block; margin: auto;padding-bottom: 8px"></span>
                        MYELMEC
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content feature-tab-content">
                    <div class="tab-pane active" id="tab6-1">
                      Il contratto comprende:
                      <ul class="list-unstyled">
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div class="badge badge-circle badge-primary mr-3">
                                <span class="ti-check"></span>
                              </div>
                            </div>
                            <div><p class="mb-0">I dispositivi più adatti alle esigenze dei tuoi utenti.</p></div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div class="badge badge-circle badge-primary mr-3">
                                <span class="ti-check"></span>
                              </div>
                            </div>
                            <div><p class="mb-0">Le configurazioni che preferisci, personalizzate dai nostri tecnici.</p>
                            </div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div class="badge badge-circle badge-primary mr-3">
                                <span class="ti-check"></span>
                              </div>
                            </div>
                            <div><span class="mb-0">La cybersecurity integrata. </span><!--<span><img
                                    v-tooltip="'Vai oltre l\'antivirus'"
                                    style="margin-top: 5px" src="/img/information.svg" width="16px"></span>--></div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div class="badge badge-circle badge-primary mr-3">
                                <span class="ti-check"></span>
                              </div>
                            </div>
                            <div><p class="mb-0">Le tue credenziali MyElmec per monitorare da subito i tuoi device
                              aziendali.
                            </p></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="tab-pane" id="tab6-2">
                      Alla tua offerta Noltech Base puoi aggiungere i seguenti servizi aggiuntivi:
                      <ul class="list-unstyled">
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div style="width: 40px" class="mr-3">
                                <img src="/img/lego.svg" alt="Add on" width="40px">
                              </div>
                            </div>
                            <div><p class="mb-0">Smart Rollout - Devi aggiornare la tua flotta? Grazie al supporto on site dei nostri tecnici programmi il cambio dei device senza interruzioni di business.</p></div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div style="width: 40px" class="mr-3">
                                <img src="/img/lego.svg" alt="Add on" width="40px">
                              </div>
                            </div>
                            <div><p class="mb-0">Blocco remoto e cancellazione certificata dei dati: puoi bloccare da
                              remoto i tuoi device e assicurarti che a fine noleggio i tuoi dati siano cancellati
                              definitivamente.
                            </p></div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div style="width: 40px" class="mr-3">
                                <img src="/img/lego.svg" alt="Add on" width="40px">
                              </div>
                            </div>
                            <div><p class="mb-0">Ipergaranzia Elmec : estendi il supporto standard del produttore con
                              una manutenzione in tutta Europa mediante device sostitutivi pre-configurati e in pronta
                              consegna.</p></div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div style="width: 40px" class="mr-3">
                                <img src="/img/lego.svg" alt="Add on" width="40px">
                              </div>
                            </div>
                            <div><p class="mb-0">Licenze Office 365: ci pensa Noltech a gestire le tue licenze a partire
                              dagli aspetti contrattuali con Microsoft fino al deploy tecnico su tutti i tuoi
                              device.</p></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="tab-pane" id="tab6-3">
                      Alla tua offerta Noltech Start  puoi aggiungere servizi utili per i tuoi utenti aziendali:
                      <ul class="list-unstyled">
                        <li class="py-2">
                          <div class="d-flex align-items-center">
                            <div>
                              <div style="width: 40px" class="mr-3">
                                <img src="/img/lego.svg" alt="Add on" width="40px">
                              </div>
                            </div>
                            <div><p class="mb-0">Easy restore.</p></div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-center">
                            <div>
                              <div style="width: 40px" class="mr-3">
                                <img src="/img/lego.svg" alt="Add on" width="40px">
                              </div>
                            </div>
                            <div><p class="mb-0">Voucher connettività powered by Eolo. </p></div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-center">
                            <div>
                              <div style="width: 40px" class="mr-3">
                                <img src="/img/lego.svg" alt="Add on" width="40px">
                              </div>
                            </div>
                            <div><p class="mb-0">Corsi di Security awareness per formare i tuoi collaboratori.</p></div>
                          </div>
                        </li>

                      </ul>
                    </div>
                    <div class="tab-pane" id="tab6-4">
                      Un’utenza MyElmec sarà attivata da subito e ti darà accesso:
                      <ul class="list-unstyled">
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div class="badge badge-circle badge-primary mr-3">
                                <span class="ti-check"></span>
                              </div>
                            </div>
                            <div><p class="mb-0">Dettaglio HW e lista SW installati per ogni singolo device
                              .</p></div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div class="badge badge-circle badge-primary mr-3">
                                <span class="ti-check"></span>
                              </div>
                            </div>
                            <div><p class="mb-0">Gestione Ticket e interventi di manutenzione.
                            </p></div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div class="badge badge-circle badge-primary mr-3">
                                <span class="ti-check"></span>
                              </div>
                            </div>
                            <div><p class="mb-0">Visibilità dei costi e dei canoni per singolo device.</p></div>
                          </div>
                        </li>
                        <li class="py-2">
                          <div class="d-flex align-items-start">
                            <div>
                              <div class="badge badge-circle badge-primary mr-3">
                                <span class="ti-check"></span>
                              </div>
                            </div>
                            <div><p class="mb-0">Controllo delle scadenze con richiesta diretta di reso/proroga o
                              rinnovo.
                            </p></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container ptb-60">
          <div class="row  justify-content-center">
            <a @click="richiediPreventivo" class="btn solid-btn">Configura il tuo equipaggiamento</a>
            <!--HubSpot Call-to-Action Code -->
            <!--<span class="hs-cta-wrapper" id="hs-cta-wrapper-2ad8cd50-69b0-4868-9ccc-8cf4d0783e7f"><span class="hs-cta-node hs-cta-2ad8cd50-69b0-4868-9ccc-8cf4d0783e7f" id="hs-cta-2ad8cd50-69b0-4868-9ccc-8cf4d0783e7f"><div id="hs-cta-ie-element"></div><a href="https://cta-redirect.hubspot.com/cta/redirect/5099766/2ad8cd50-69b0-4868-9ccc-8cf4d0783e7f"  target="_blank" ><img class="hs-cta-img" id="hs-cta-img-2ad8cd50-69b0-4868-9ccc-8cf4d0783e7f" style="border-width:0px;" src="https://no-cache.hubspot.com/cta/default/5099766/2ad8cd50-69b0-4868-9ccc-8cf4d0783e7f.png"  alt="RICHIEDI UN PREVENTIVO GRATUITO"/></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(5099766, '2ad8cd50-69b0-4868-9ccc-8cf4d0783e7f', {"region":"na1"}); </script></span>-->
            <!-- end HubSpot Call-to-Action Code -->
          </div>
        </div>
      </section>
      <!--features section end-->





      <!--promo section start-->
      <section v-if="false" id="ilnoleggio" class="promo-section ptb-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-8">
              <div class="section-heading text-center mb-5">
                <h2>Ti sfidiamo a trovare un altro partner così</h2>
              <p class="lead">
                Siamo la business unit di Elmec Informatica interamente dedicata ai servizi di gestione dei device.
              </p>
              </div>
            </div>
          </div>
          <div class="row equal">
            <div class="col-md-4 col-lg-4">
              <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div class="circle-icon ">
                  <img src="/img/semplice.svg" width="30" alt="Easy icon"
                       class="img-fluid">
                </div>
                <h5 class="color-primary">SEMPLICE</h5>
                <p>
                  Partire per un viaggio non è mai stato così facile! Ti semplifichiamo la vita perché siamo il tuo  <strong class="color-secondary font-weight-bold">unico interlocutore</strong>, una guida affidabile che ti accompagnerà durante tutto il percorso. Ci adattiamo alle esigenze della tua azienda fornendoti l’equipaggiamento più adatto ai bisogni dei tuoi collaboratori: tecnologie di ultima generazione e un  <strong class="color-secondary font-weight-bold">servizio di assistenza di prima classe</strong>.</p>

              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div :style="'box-shadow: 0 3px 20px 0px rgb(42 160 187 / 61%);'"
                   class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div class="circle-icon ">
                  <img src="/img/sicuro.svg" width="30" alt="Secure icon"
                       class="img-fluid">
                </div>
                <h5 class="color-primary">SICURO</h5>
                <p>La sicurezza si fa, non si aggiunge. Durante un viaggio possono sempre capitare degli imprevisti, per questo <strong class="color-secondary font-weight-bold">proteggere i tuoi utenti e i dati aziendali</strong> è un fattore che non puoi trascurare. Ecco perché abbiamo integrato la Cybersecurity tra i servizi di gestione dei dispositivi della tua azienda. Andiamo oltre ai semplici antivirus con un team di 30 cybersecurity expert in grado di evidenziare eventuali criticità e consigliarti i migliori interventi preventivi.</p>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div class="circle-icon ">
                  <img src="/img/trasparente.svg" width="30" alt="Transparent icon"
                       class="img-fluid">
                </div>
                <h5 class="color-primary">TRASPARENTE</h5>
                <p>Basta con gli asterischi! Abbiamo creato un <strong class="color-secondary font-weight-bold">contratto one-page con una sola firma</strong> per garantirti semplicità e trasparenza. Non siamo solo esperti di tecnologie, Noltech vanta anche un team di consulenti che si occupano di tutta la parte amministrativa, riducendo al minimo i vincoli burocratici. I costi poi, saranno in chiaro, con un canone ricorsivo fisso e nessun problema per il tuo Budget IT.</p>
              </div>
            </div>
          </div>
          <div class="row equal pt-60">
            <div class="col-md-4 col-lg-4">
              <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div class="circle-icon ">
                  <img src="/img/flessibile.svg" width="30" alt="flexibility icon"
                       class="img-fluid">
                </div>
                <h5 class="color-primary">FLESSIBILE</h5>
                <p>Decidi tu per quanto tempo durerà il viaggio della tua azienda! Noltech ti offre la massima flessibilità nella durata e nelle forniture così che potrai partire quando vorrai, per il tempo che desideri e con il migliore equipaggiamento. Ci adattiamo alle tue scadenze garantendoti tempi di consegna record e contratti di durata variabile da 1 mese a 5 anni, con la possibilità di <strong class="color-secondary font-weight-bold">ripensarci senza sorprese.</strong> </p>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div class="circle-icon">
                  <img src="/img/affidabile.svg" width="40" alt="trustworthy icon"
                       class="img-fluid">
                </div>
                <h5 class="color-primary">AFFIDABILE</h5>
                <p>La nostra assistenza <strong class="color-secondary font-weight-bold">"centopercentonoi"</strong> è davvero un servizio di prima classe. I nostri 155 tecnici certificati ti accompagneranno nel nostro viaggio insieme, dalla preparazione dei dispositivi, alla loro manutenzione, fino alla sostituzione dei device e al loro smaltimento. In più, se alcuni dei tuoi collaboratori si trovano all’estero, garantiamo assistenza in tutta <strong class="color-secondary font-weight-bold">Europa</strong>. </p>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div class="circle-icon ">
                  <img src="/img/sottoControllo.svg" width="40" alt="inder control icon"
                       class="img-fluid">
                </div>
                <h5 class="color-primary">SOTTO CONTROLLO</h5>
                <p>Dove sono i tuoi collaboratori? Il loro equipaggiamento funziona correttamente? Hai dei ticket aperti? <strong class="color-secondary font-weight-bold">Monitora</strong> tutte le informazioni tecniche e amministrative dalla piattaforma MyElmec. Da un unico punto di accesso potrai gestire in tempo reale tutti gli asset IT dell’azienda in modo semplice e intuitivo, anche tramite mobile app. </p>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row pt-60 justify-content-center">
              <a href="/configuration" class="btn solid-btn page-scroll">Richiedi un preventivo</a>
            </div>
          </div>
        </div>
      </section>
      <!--promo section end-->


      <!--our video promo section start-->
      <section v-if="false" id="numeri" class="video-promo ptb-60 background-img"
               style="background: url('/img/hero-bg-1.jpg') no-repeat center center / cover">
        <div id="counter" class="container">
          <div class="row justify-content-center">
            <div class="container mt-lg-5">
              <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                  <div class="single-fact p-3 text-center">
                    <h3 class="title display-4 font-weight-bolder counter-value" data-count="155">0</h3>
                    <p class="mb-0">Tecnici certificati</p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                  <div class="single-fact p-3 text-center">
                    <h3 class="title display-4 font-weight-bolder counter-value" data-count="6600">0</h3>
                    <p class="mb-0">Ticket workplace gestiti nel 2020</p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                  <div class="single-fact p-3 text-center">
                    <h3 style="display: inline-block" class="title display-4 font-weight-bolder counter-value"
                        data-count="90">0</h3>
                    <h5 style="display: inline-block">&percnt;</h5>
                    <p class="mb-0">Soddisfazione utenti first call resolution rate

                    </p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                  <div class="single-fact p-3 text-center">
                    <h3 style="display: inline-block" class="title display-4 font-weight-bolder counter-value"
                        data-count="5">0 </h3><h5 style="display: inline-block">mln &euro;</h5>
                    <p class="mb-0">Device nel ns magazzino
                    </p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                  <div class="single-fact p-3 text-center">
                    <h3 class="title display-4 font-weight-bolder counter-value" data-count="1550">0</h3>
                    <p class="mb-0">Muletti in
                      pronta consegna
                    </p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                  <div class="single-fact p-3 text-center">
                    <h3 class="title display-4 font-weight-bolder counter-value" data-count="4600">0</h3>
                    <p class="mb-0">Ricambi
                      certificati
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--our pricing packages section start-->
     <!-- <section id="pricing" class="package-section gray-light-bg ptb-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="section-heading text-center mb-5">
                <h2>Perchè i nostri clienti hanno scelto Noltech </h2>
                <p class="lead">
                  Abbiamo cambiato le regole per creare un modello che semplifichi la vita dei nostri clienti.
                  Mettici alla prova, <strong class="color-primary font-weight-bold">i nostri contratti sono senza
                  asterischi.</strong>

                </p>
              </div>
            </div>
          </div>
          <table class="table ">
            <thead class="table-info">
            <tr>

              <th><strong class=" font-weight-bold">Servizi più richiesti dalle aziende</strong></th>
              <th style="text-align: center"><strong class=" font-weight-bold">NolTech</strong>​​</th>
              <th style="text-align: center"><strong class=" font-weight-bold">Gli altri</strong></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td> Unico Interlocutore </td>
              <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                  class="img-fluid"></td>
              <td style="text-align: center">

                <img src="/img/close.svg" width="30" alt="check" class="img-fluid">
                <img src="/img/information.svg" width="16px" v-tooltip.right="'I contratti di noleggio coinvolgono spesso due o più società diverse per gli aspetti tecnici ed amministrativi. Chi è il tuo vero interlocutore?'">
              </td>

            </tr>
            <tr>
              <td>Ci ripenso: restituisci la tecnologia a zero spese</td>
              <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                  class="img-fluid"></td>
              <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                  class="img-fluid">
                <img src="/img/information.svg" width="16px" v-tooltip.right="'Fai attenzione alle condizioni di restituzione di uno o più beni a fine contratto: spesso nascondono clausole vincolanti'">
              </td>

            </tr>
            <tr>
              <td>Contratto aperto e trasparente</td>
              <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                  class="img-fluid"></td>
              <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                  class="img-fluid"><img src="/img/information.svg" width="16px"
                                                 v-tooltip.right="'Controlla le clausole assicurative, di esclusione in caso di smarrimento/furto con destrezza o i vincoli di estensione del contratto. se riesci a trovarle'">
              </td>

            </tr>
            <tr>
              <td>Tacito Rinnovo</td>
              <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                  class="img-fluid"></td>
              <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                  class="img-fluid"><img style="padding-left: 1px"
                                                                         src="/img/information.svg" width="16px"
                                                                         v-tooltip.right="'Controlla le clausole assicurative, di esclusione in caso di smarrimento/furto con destrezza o i vincoli di estensione del contratto. se riesci a trovarle'"
              >
              </td>

            </tr>
            <tr>
              <td>Servizio EU</td>
              <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                  class="img-fluid"></td>
              <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                  class="img-fluid"><img style="padding-left: 1px"
                                                                         src="/img/information.svg" width="16px"
                                                                         v-tooltip.right="'Sei un business europeo/multinazionale: ti occorre una fatturazione separabile su più country. Con altre società avrai più contratti e dovrai gestire il “transfer price.'">
              </td>

            </tr>
            <tr>
              <td>Flessibilità</td>
              <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                  class="img-fluid"></td>
              <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                  class="img-fluid"><img style="padding-left: 1px"
                                                                         src="/img/information.svg" width="16px"
                                                                         v-tooltip.right="'Se il tuo business è scalabile nel tempo e vuoi aggiungere device di volta in volta, possono garantirti più lotti noleggiati in tempi diversi?'">
              </td>

            </tr>
            <tr>
              <td>Intervento nelle 24 h</td>
              <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                  class="img-fluid"></td>
              <td style="text-align: center"><img style="margin-right: 14px" src="/img/question-mark.svg" width="30"
                                                  alt="check"
                                                  class="img-fluid"></td>

            </tr>
            <tr>
              <td>EmbeddedCyberSecurity</td>
              <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                  class="img-fluid"></td>
              <td style="text-align: center"><img style="margin-right: 14px" src="/img/close.svg" width="30" alt="check"
                                                  class="img-fluid"></td>

            </tr>
            <tr>
              <td>Competenza Tecnica certificata</td>
              <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                  class="img-fluid"></td>
              <td style="text-align: center"><img style=" margin-right: 14px" src="/img/close.svg" width="30" alt="check"
                                                  class="img-fluid"></td>
            </tr>

            </tbody>
          </table>
        </div>
      </section>-->
      <!--our pricing packages section end-->



      <section v-if="false" id="vantaggi" class="ptb-100 gray-light-bg">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-8">
              <div class="section-heading text-center mb-5">
                <h2>La guida ideale per diversi tipi di viaggiatori</h2>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-8 col-10">
              <div class="single-team-member position-relative">
                <div class="team-image">
                  <img src="img/viaggiatore1.png" alt="I vantaggi del noleggio Noltech per l'IT Manager" class="img-fluid rounded-circle">
                </div>
              </div>
              <h4 class="pt-60"> IT MANAGER</h4>
              <p >
                Garantire il funzionamento del parco IT della tua azienda non è mai stato così facile. Noltech semplifica il tuo lavoro supportandoti con la gestione, assistenza e riparazione dei device. In più, dalla piattaforma MyElmec potrai monitorare lo status di ciascun dispositivo in modo semplice e intuitivo.

              </p>
              <p>
                <a class="linkBlu" @click="goToRoute('ItManager')" > Scopri i vantaggi per l'IT Manager</a>
              </p>
            </div>
            <div class="col-lg-3 col-sm-8 col-10">
              <div class="single-team-member position-relative">
                <div class="team-image">
                  <img src="img/viaggiatore2.png" alt="I vantaggi del noleggio Noltech per il CFO" class="img-fluid rounded-circle">
                </div>

              </div>
              <h4 class="pt-60">CFO</h4>
              <p>
                Produttori, fornitori, consulenti esterni e rappresentanti di terze parti che intervengono nel processo: muoversi tra tutti questi interlocutori è sempre più complicato. Il viaggio con Noltech è molto più semplice e lineare perché saremo il tuo unico punto di riferimento durante tutto il percorso, permettendoti anche di ottimizzare il budget IT.

              </p>
              <p>
                <a class="linkBlu" @click="goToRoute('Cfo')" > Scopri i vantaggi per il CFO</a>
              </p>
            </div>

            <div class="col-lg-3 col-sm-8 col-10">
              <div class="single-team-member position-relative">
                <div class="team-image">
                  <img src="img/viaggiatore3.png" alt="I vantaggi del noleggio Noltech per l'HR Manager" class="img-fluid rounded-circle">
                </div>

              </div>
              <h4 class="pt-60">HR MANAGER</h4>
              <p>
                I dispositivi IT non sono solo un mezzo per garantire l’operatività dei tuoi collaboratori in sede e da remoto, ma anche un vero e proprio benefit. Offri al personale della tua azienda il migliore equipaggiamento con device di ultima generazione e una configurazione personalizzata.

              </p>
              <p>
                <a class="linkBlu" @click="goToRoute('HrManager')" > Scopri i vantaggi per l'HR Manager</a>
              </p>
            </div>
          </div>
        </div>
      </section>





      <section v-if="false" id="chisiamo" class="ptb-100 ">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-8">
              <div class="section-heading text-center mb-5">
                <h2>Il gruppo</h2>
                <p class="lead">
                  L’esperienza non si improvvisa.
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-8">
              <div class="section-heading text-center mb-5">
                <p class="lead">
                  Noltech fa parte <strong class="color-secondary font-weight-bold">dell’ecosistema Smart di
                  Elmec</strong>: passione per la tecnologia da 50 anni.
                  Non come semplice promessa ma con la creazione di realtà innovative su altrettante specializzazioni
                  tecnologiche. Questo ci rende una delle principali realtà ICT in Italia.

                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-2 offset-lg-2 marchielmec">
              <img src="/img/elmec.svg" alt="elmec logo" width="160" class="img-fluid">
              <div class="labelChisiamo">Servizi cloud e digital Workplace</div>
            </div>
            <div class="col-lg-2 marchielmec">
              <img src="/img/cybergon-payoff.svg" alt="cybergon logo" width="160" class="img-fluid">
              <div class="labelChisiamo">Cyber Security</div>
            </div>
            <div class="col-lg-2 marchielmec">
              <img style="padding-top: 6px" src="/img/elmec3d.svg" alt="elmec3d logo" width="160" class="img-fluid">
              <div class="labelChisiamo">Stampa 3D industriale</div>
            </div>
            <div class="col-lg-2 marchielmec">
              <img style="padding-top: 11px" src="/img/suisse.svg" alt="elmec suisse logo" width="160" class="img-fluid">
              <div class="labelChisiamo">Business continuity</div>
            </div>
          </div>
          <div class="row mt-lg-5">
            <div class="col-lg-2 offset-lg-2 marchielmec">
              <img src="/img/eolo.svg" alt="eolo logo" width="90" class="img-fluid">
              <div class="labelChisiamo">Connettività</div>
            </div>
            <div class="col-lg-2 marchielmec">
              <img src="/img/solar.svg" alt="elmec solar logo" width="120" class="img-fluid">
              <div class="labelChisiamo">Green energy</div>
            </div>
            <div class="col-lg-2 marchielmec">
              <img src="/img/nts.jpg" alt="nts logo" width="80" class="img-fluid">
              <div class="labelChisiamo">ERP</div>
            </div>
            <div class="col-lg-2 marchielmec">
              <img src="/img/everynet_logo.svg" alt="everynet logo" width="120" class="img-fluid">
              <div class="labelChisiamo">Internet of Things</div>
            </div>

          </div>
        </div>

      </section>


      <!--FAQ-->
      <!--<section id="faq" class="gray-light-bg ptb-100 ">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-8">
              <div class="section-heading text-center mb-5">
                <h2>FAQ</h2>
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-lg-6">
              <div id="accordion-1" class="accordion accordion-faq">

                <div class="card">
                  <div class="card-header py-4 collapsed" id="heading-1-1" data-toggle="collapse" role="button"
                       data-target="#collapse-1-1" aria-expanded="false" aria-controls="collapse-1-1">
                    <h6 class="mb-0">A chi è rivolto il
                      vostro noleggio?</h6>
                  </div>
                  <div id="collapse-1-1" class="collapse" aria-labelledby="heading-1-1" data-parent="#accordion-1"
                       style="">
                    <div class="card-body">
                      <p>I nostri servizi e i nostri tecnici certificati sono da oltre 50 anni il valore aggiunto che
                        semplifica la vita delle <strong class="font-weight-bold">aziende</strong>: se la tua
                        infrastruttura Workplace richiede operatività complesse, controllo dei costi, competenze sempre
                        aggiornate e tempi di risposta brevi, <strong class="font-weight-bold">scrivici</strong>.
                        L’offerta Noltech non è rivolta ai privati.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header py-4" id="heading-1-2" data-toggle="collapse" role="button"
                       data-target="#collapse-1-2" aria-expanded="false" aria-controls="collapse-1-2">
                    <h6 class="mb-0">Quanto tempo ci vorrà
                      per configurare e consegnare un pc?</h6>
                  </div>
                  <div id="collapse-1-2" class="collapse" aria-labelledby="heading-1-2" data-parent="#accordion-1">
                    <div class="card-body">
                      <p>La preparazione del tuo device <strong class="font-weight-bold">fa la differenza</strong>, per
                        questo vantiamo 155 tecnici certificati per garantirti il massimo della competenza. Ma anche la
                        <strong class="font-weight-bold">velocità</strong>; ci siamo cronometrati, a configurare un PC
                        non abbiamo rivali: 20' invece di 2h!<br>
                        Gestiamo grandi numeriche con consegne celeri e puntuali personalizzando i tuoi device con
                        immagini master dedicate. <strong class="font-weight-bold"></strong> <strong
                            class="font-weight-bold"></strong>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header py-4" id="heading-1-3" data-toggle="collapse" role="button"
                       data-target="#collapse-1-3" aria-expanded="false" aria-controls="collapse-1-3">
                    <h6 class="mb-0">E’ possibile programmare la consegna dei pc? Dove è attivo il servizio?
                    </h6>
                  </div>
                  <div id="collapse-1-3" class="collapse" aria-labelledby="heading-1-3" data-parent="#accordion-1">
                    <div class="card-body">
                      <p>Sappiamo bene che le nuove modalità di lavoro, in ufficio o da remoto, complicano la vita al
                        Team IT. Per questo, i dispositivi vengono <strong
                            class="font-weight-bold">pre-configurati</strong> nei nostri laboratori e poi spediti su
                        richiesta dei nostri clienti in base alle singole esigenze, anche a casa dei dipendenti in smart
                        working. Il servizio è attivo in <strong class="font-weight-bold">tutta Europa</strong> con
                        spedizionieri di fiducia.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div id="accordion-2" class="accordion accordion-faq">

                <div class="card">
                  <div class="card-header py-4" id="heading-2-1" data-toggle="collapse" role="button"
                       data-target="#collapse-2-1" aria-expanded="false" aria-controls="collapse-2-1">
                    <h6 class="mb-0">E se dovessi restituire dei device non più in uso?</h6>
                  </div>
                  <div id="collapse-2-1" class="collapse" aria-labelledby="heading-2-1" data-parent="#accordion-2">
                    <div class="card-body">
                      <p>Il business evolve continuamente. Per questo la formula Noltech non ti penalizza se cambi idea
                        o devi restituire device non più in uso in azienda. Con l’opzione <strong
                            class="font-weight-bold">“ci ripenso”</strong>, dopo i primi sei mesi potrai restituire fino
                        al 50% dei tuoi beni noleggiati senza spese aggiuntive. </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header py-4" id="heading-2-2" data-toggle="collapse" role="button"
                       data-target="#collapse-2-2" aria-expanded="false" aria-controls="collapse-2-2">
                    <h6 class="mb-0">Cosa accade al termine del noleggio?
                    </h6>
                  </div>
                  <div id="collapse-2-2" class="collapse" aria-labelledby="heading-2-2" data-parent="#accordion-2">
                    <div class="card-body">
                      <p>Al termine del noleggio, puoi scegliere in base alle tue esigenze se procedere con un rinnovo
                        tecnologico o estendere la durata del contratto in scadenza.
                        In alternativa, programmeremo insieme la dismissione e la riconsegna dei beni noleggiati,
                        <strong class="font-weight-bold">senza alcun vincolo contrattuale</strong>.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header py-4" id="heading-2-3" data-toggle="collapse" role="button"
                       data-target="#collapse-2-3" aria-expanded="false" aria-controls="collapse-2-3">
                    <h6 class="mb-0">Come posso tenere monitorati tutti gli asset a noleggio?
                    </h6>
                  </div>
                  <div id="collapse-2-3" class="collapse" aria-labelledby="heading-2-3" data-parent="#accordion-2">
                    <div class="card-body">
                      <p>Ti attiveremo fin da subito tutte le utenze necessarie nel nostro portale MyElmec, dove
                        gestirai i device dei tuoi utenti in <strong class="font-weight-bold">modo
                          centralizzato</strong>: puoi cercare per serial number o per utente, avere informazioni HW
                        precise e la lista dei software installati per singoli utenti, gestire i ticket e gli interventi
                        di manutenzione e monitorare i costi e le durate dei canoni per singolo device.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-60  justify-content-center">
            <a @click="richiediPreventivo" class="btn solid-btn">Richiedi un preventivo gratuito</a>
          </div>

        </div>
      </section>-->


      <!--client section start-->
      <section v-if="false" class="client-section ptb-100 gray-light-bg">
        <div class="container">
          <!--clients logo start-->
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="section-heading text-center mb-5">
                <!-- <h5 class="color-primary">BEST IN CLASS</h5>-->
                <h2>Partner tecnologici</h2>
                <p class="lead">
                  Abbiamo selezionato i partner migliori per offrirti un pacchetto di offerta su misura per ogni tua
                  esigenza di business
                </p>
              </div>
            </div>
          </div>
          <div class="row align-items-center">

            <div class="col-lg-2 offset-lg-2 text-center">
              <img src="/img/apple.png" alt="apple logo" width="160" class="img-fluid">
            </div>
            <div class="col-lg-2 text-center">
              <img src="/img/lenovoPlatinum.png" alt="lenovo logo" width="160" class="img-fluid">
            </div>
            <div class="col-lg-2 text-center">
              <img src="/img/MicrosoftGold.png" alt="microsoft logo" width="160" class="img-fluid">
            </div>
            <div class="col-lg-2 text-center">
              <img src="/img/HPPlatinum.png" alt="hp logo" width="160" class="img-fluid">
            </div>

          </div>

        </div>
        <!--clients logo end-->
      </section>
      <!--client section start-->

      <section v-if="false" class="ptb-100 ">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-9 col-lg-7">
              <div class="hero-content-left text-center">
                <h2>Segui i nostri Tech Update! </h2>
                <p class="lead">
                  I nostri tecnici raccontano qualche "insight" sugli ultimi trend del mondo Workplace dedicati alle
                  aziende.
                </p>
                <!--<form action="#" method="post" class="subscribe-form text-center m-auto">
                    <div class="d-flex align-items-center">
                        <input type="text" class="form-control input" id="email" name="email" placeholder="info@noltech.it">
                        <input type="submit" class="button btn solid-btn" id="submit" value="Iscriviti">
                    </div>
                </form>-->
                <hubspot-signup :form-id="'66131cbd-f414-4e94-bcd8-bb10ad1a435b'"/>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
    <!--body content wrap end-->

  </div>
</template>

<script>
  import HubspotSignup from '../HubspotForm'
  import {commonMixins} from '../../mixins/common-mixins'
  import Multiselect from '../global/Multiselect'
  import MyInput from '../global/MyInput'
  import Slider from '../global/Slider'

  export default {
    name: 'Home',
    mixins: [commonMixins],
    components: {Slider, MyInput, Multiselect, HubspotSignup},
    watch: {},
    data () {
      return {
        selectedDevice: '',
        deviceQuantity: null
      }
    },
    mounted: function () {
      this.goTopPage()
    },
    methods: {
      selectDevice: function (device) {
        this.$router.push({
          name: 'Configurator',
          params: {
            deviceType: device,
            deviceQuantity: this.deviceQuantity
          }
        })
      },
      goToRoute: function (route) {
        this.$router.push({
            name: route,
        })
      }
    }
  }
</script>

<style lang="less">
  #home {
    .configuration-container {
      .device-selection {
        display: flex;

        .type {
          width: 250px;

          .multiselect {
            .multiselect__tags {
              border: 2px solid #C4CBCC;
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
              height: 40px;
              .multiselect__placeholder {
                font-size: 16px;
                padding: 4px 0 0 0;
              }
              .multiselect__input {
                padding: 6px 0 0 0;
              }
              .multiselect__single {
                padding: 8px 0 0 0;
              }
            }
            .multiselect__select {
              padding-left: 25px;
              top: 2px;
            }
          }
        }

        .quantity {
          /*font-family: inherit;*/
          /*font-size: 16px;*/
          /*background: #F4F8FB;*/
          /*min-height: 16px;*/
          /*height: 40px;*/
          /*padding: 2px 10px;*/
          /*color: gray;*/
          /*outline: none;*/


          /*&::-webkit-input-placeholder {*/
          /*  font-size: 14px;*/
          /*  color: gray;*/
          /*  opacity: 1;*/
          /*}*/
          &.my-input-global {
            width: 40%;

            .my-input {
              border: 2px solid #C4CBCC;
              border-radius: 5px;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              border-left: none;
              /*width: auto;*/

              &::-webkit-input-placeholder {
                font-size: 16px;
                color: #33475b !important;
                opacity: 1 !important;
                letter-spacing: 0 !important;
              }
            }
          }
        }
      }

      .configuration-btn {
        margin-top: 20px;
        width: 240px;
        height: 40px;
        outline: none;
        background: #F3F7FA 0% 0% no-repeat padding-box;
        border: 1px solid #2C5593;
        border-radius: 25px;
        opacity: 1;
        color: #2C5593;
        font-size: 16px;
        font-weight: bold;
        padding-top: 4px;
      }
    }

    .btn {
      cursor: pointer;
    }
  }

</style>
